const en = require("./locales/en.json")
const he = require("./locales/he.json")

const languages = ["he", "en"]
const defaultLanguage = "he"
const messages = { en, he }

exports.defaultLanguage = defaultLanguage
exports.languages = languages
exports.messages = messages
