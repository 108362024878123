import { setDoc, doc, deleteDoc } from "firebase/firestore/lite"
import React, { useState, useCallback, useMemo } from "react"
import { defaultLanguage, languages } from "../../../i18n"
import { fs } from "../../../utils/firebase"

const initRec = {
  lng: defaultLanguage,
  recommendation: "",
  name: "",
}

export default function AddRecommendation() {
  const recommendations = useMemo(
    () =>
      typeof window !== "undefined"
        ? JSON.parse(window?.localStorage.getItem("recommendations"))
        : [],
    []
  )
  const [newRec, setNewRec] = useState(initRec)

  const handleAddRec = useCallback(
    () =>
      setDoc(doc(fs, "recommendations", newRec.name), newRec).then(() =>
        setNewRec(initRec)
      ),
    [newRec]
  )

  return (
    <div>
      <p className="collapsed-p"> Add:</p>
      <div>
        <div>
          <label htmlFor="rec-lng">lng: </label>
          <select
            id="rec-lng"
            name="rec-lng"
            onChange={e =>
              setNewRec(prev => ({ ...prev, lng: e.target.value }))
            }
          >
            {languages.map(lng => (
              <option value={lng} key={`tour-${lng}`}>
                {lng}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label htmlFor="new-rec-name">name</label>
          <input
            name="new-rec-name"
            value={newRec.name}
            onChange={e =>
              setNewRec(prev => ({ ...prev, name: e.target.value }))
            }
          />
        </div>
        <div>
          <label htmlFor="new-rec">recommendation</label>
          <input
            name="new-rec"
            value={newRec.recommendation}
            onChange={e =>
              setNewRec(prev => ({ ...prev, recommendation: e.target.value }))
            }
          />
        </div>
        <button onClick={handleAddRec}>save</button>
      </div>
      <br />
      {recommendations.map(rec => (
        <div className="recomendation-row" key={rec.name}>
          {rec.name} ---
          {rec.recommendation}{" "}
          <button
            onClick={() => deleteDoc(doc(fs, "recommendations", rec.name))}
          >
            delete
          </button>
        </div>
      ))}
    </div>
  )
}
